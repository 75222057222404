/* eslint-env browser */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AUSpinnerComponent from '@aarhus-university/au-lib-react-components/src/components/AUSpinnerComponent';
// import FacebookPostComponent from '../components/FacebookPostComponent';
import TwitterStatusComponent from '../components/TwitterStatusComponent';
import labels from '../i18n';
import getFacebook from '../actions/getFacebook';
import getTwitter from '../actions/getTwitter';
import '../styles/styles.scss';

const defaultTwitterSearch = 'aarhusuni';
const defaultElfsightApp = 'd5eb5118-ebf0-406f-b609-bdc54fc5034f';
const defaultFollowLinks = [
  {
    name: 'Facebook',
    uri: 'https://www.facebook.com/UniAarhus',
  },
  {
    name: 'Bluesky',
    uri: 'https://bsky.app/profile/did:plc:32imedc3eyp7jgro5uwcpmcg',
  },
  {
    name: 'Twitter',
    uri: 'https://x.com/AarhusUni',
  },
  {
    name: 'Instagram',
    uri: 'https://www.instagram.com/aarhusuni/?hl=en',
  },
  {
    name: 'LinkedIn',
    uri: 'https://www.linkedin.com/company/aarhus-university-denmark-?trk=hb_tab_compy_id_4648',
  },
  {
    name: 'YouTube',
    uri: 'https://www.youtube.com/user/AarhusUniversity',
  },
];

const importer = {
  url: (url, defer = false) => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    if (defer) {
      script.defer = true;
    }
    script.addEventListener('load', () => resolve(script), false);
    script.addEventListener('error', () => reject(script), false);
    document.body.appendChild(script);
  }),
  urls: (urls) => Promise.all(urls.map(importer.url)),
};
class AppComponent extends Component {
  static convertDate(d, primaryLang = true) {
    const date = new Date(d);
    const day = date.getDate() < 10 ? (`0${date.getDate()}`) : date.getDate();
    const month = date.getMonth() + 1 < 10 ? (`0${date.getMonth() + 1}`) : (date.getMonth() + 1);
    const hours = date.getHours() < 10 ? (`0${date.getHours()}`) : date.getHours();
    const minutes = date.getMinutes() < 10 ? (`0${date.getMinutes()}`) : date.getMinutes();
    if (primaryLang) {
      return `${day}-${month}-${date.getFullYear()} ${hours}:${minutes}`;
    }
    return `${date.getFullYear()}-${month}-${day} ${hours}:${minutes}`;
  }

  static highlight(text, service) {
    const re = /(#\w[a-zA-ZøæåØÆÅ]+)|(@\w+)|(http|https):\/\/[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,3}(\/\S*)?/g;
    return text.replace(re, (match, p1, p2, p3) => {
      if (typeof p2 !== 'undefined') {
        return `<a class="highlight" href="${service}${match}">${match}</a>`;
      }

      if (typeof p3 !== 'undefined') {
        return `<a class="highlight" href="${match}">${match}</a>`;
      }
      return `<span class="highlight">${match}</span>`;
    });
  }

  render() {
    /* eslint-disable react/no-array-index-key */
    const { social, lang } = this.props;
    /*
    const renderFacebook = social.Facebook.Posts.slice(0, 2).map((f, i) => (
      <div key={i} className="column large-3 medium-3 small-12">
        <FacebookPostComponent
          post={f}
          convertDate={AppComponent.convertDate}
          highlight={AppComponent.highlight}
          accessToken={social.Facebook.AccessToken}
        />
      </div>
    ));
    */

    const renderTwitter = social.Twitter.slice(0, 4).map((t, i) => (
      <div key={i} className="column large-3 medium-3 small-12">
        <TwitterStatusComponent
          status={t}
          convertDate={AppComponent.convertDate}
          highlight={AppComponent.highlight}
        />
      </div>
    ));

    // eslint-disable-next-line no-underscore-dangle
    let _followLinks = defaultFollowLinks;
    if (typeof window.followLinks !== 'undefined') {
      _followLinks = window.followLinks;
    }

    return (
      <AUSpinnerComponent
        loaded={social.loaded}
        loadingCondition={!social.loaded}
        onLoad={() => {
          const { actions } = this.props;
          const cachePeriod = 3600;
          // const fbAccount = lang === 'da' ? 'UniAarhus' : 'aarhusuni';
          const twAccount = typeof window.twitterSearch !== 'undefined' ? window.twitterSearch : defaultTwitterSearch;
          // actions.getFacebook(cachePeriod, fbAccount, 2);
          if (twAccount !== '') {
            actions.getTwitter(cachePeriod, twAccount, 4);
          }

          setTimeout(() => {
            importer.url('https://apps.elfsight.com/p/platform.js', true);
          }, 1000);
        }}
      >
        <div className="column large-12 medium-12 small-12">
          <div className="row">
            <div className="column large-9 medium-9 small-12">
              <h3>{labels[lang].AUonSoMe}</h3>
            </div>
            <div className="column large-3 medium-3 small-12">
              <ul className="resetlist follow">
                <li>
                  {labels[lang].followUs}
                  :
                  {' '}
                </li>
                {
                  _followLinks.map((link) => (
                    <li key={link.name} className={link.name.toLowerCase()}>
                      <a href={link.uri}>{link.name}</a>
                    </li>
                  ))
                }
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="column large-12 medium-6 small-12 small">
              <div className={`elfsight-app-${typeof window.elfsightApp !== 'undefined' ? window.elfsightApp : defaultElfsightApp}`} />
            </div>
          </div>
          <div className="row twitter">
            {renderTwitter}
          </div>
        </div>
      </AUSpinnerComponent>
    );
  }
}

AppComponent.propTypes = {
  actions: PropTypes.shape({
    getTwitter: PropTypes.func.isRequired,
  }).isRequired,
  social: PropTypes.shape({
    Twitter: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    loaded: PropTypes.bool.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const props = {
    social: state.social,
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    getFacebook,
    getTwitter,
  };

  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

AppComponent.displayName = 'AppComponent';
export default connect(mapStateToProps, mapDispatchToProps)(AppComponent);
